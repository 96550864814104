<template>
  <div>
    <Loading v-if="loading" />
    <div class="flex flex-col font-Inter px-10">
      <div class="lg:flex lg:items-center lg:justify-between mb-3">
        <div class="flex-1 min-w-0 flex justify-between py-4">
          <div
            class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
          >
            <h2
              class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
            >
              Note de crédit N° {{ invoice.document_no }}
            </h2>
          </div>
        </div>
      </div>
      <div class="mb-20 overflow-y-auto">
        <div class="flex flex-col mx-auto bg-white p-5 border shadow">
          <el-form
            :model="invoice"
            :rules="rules"
            ref="invoiceForm"
            :label-position="labelPosition"
          >
            <div class="flex justify-between items-start">
              <div class="flex flex-col w-1/2">
                <search-customer
                  @customer="handleSelectedCustomer"
                  @search-customer="handleSearchCustomer"
                  :customerInfo="customerInfo"
                  :customers="customers"
                />
              </div>
              <div class="flex flex-col justify-end items-end w-1/2">
                <div
                  class="text-3xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
                >
                  Montant TTC: {{ totalTTC | moneyFilter }} {{user.currency_code}}
                </div>
                <el-form-item label="Date" prop="date">
                  <el-date-picker
                    v-model="invoice.date"
                    type="date"
                    placeholder="jj-mm-aaaa"
                    format="dd MMM.yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Agent commercial">
                  <el-select
                    v-model="invoice.sale_agent_number"
                    filterable
                    placeholder="Selectionner"
                    clearable
                  >
                    <el-option
                      v-for="item in employees"
                      :key="item.number"
                      :label="item.full_name"
                      :value="item.number"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Réference">
                  <el-input v-model="invoice.reference"> </el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>

          <div class="customer-white mt-6">
            <add-article
              :articles="articles"
              :items="items"
              :subTotal="totalHT"
              :totalTTC="totalTTC"
              :sale="true"
              @delete="handleDeleteItem"
              @search-article="handleSearchArticle"
              @add-item-article="handeAddArticle"
              @discount="updateDiscount"
              @percentDiscount="updatePercentDiscount"
              @taxe="updateTaxe"
              @shipping="updateShipping"
            />
          </div>
        </div>
      </div>
    </div>
    <page-footer>
      <div class="flex justify-between px-10 py-2">
        <div>
          <div
            class="text-2xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncatemt-2 my-2"
          >
            TOTAL: {{ totalTTC | moneyFilter }} {{user.currency_code}}
          </div>
        </div>

        <div>
          <el-button
            type="info"
            plain
            @click="handleCreate('DRAFT')"
            :disabled="!itemsNoEmpty"
            >Enregistrer au brouillon</el-button
          >
          <el-button
            type="primary"
            @click="handleCreate('OPEND')"
            :disabled="!itemsNoEmpty"
            >Enregistrer et quitter</el-button
          >
        </div>
      </div>
    </page-footer>
  </div>
</template>

<script>
import { getDocumentSaleNo, newDocument } from "@/api/sales2";
import { getEmployeesList } from "@/api/employee";
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import AddArticle from "@/components/AddArticle";
import SearchCustomer from "@/components/AddCustomer";
import PageFooter from "@/components/PageFooter";
import Loading from "@/components/Loanding/Full";
import {mapGetters} from "vuex";

export default {
  name: "CREDITNOTE-CREATE",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { AddArticle, SearchCustomer, PageFooter, Loading },
  data() {
    return {
      typeDoc: "CREDIT_NOTE",
      labelPosition: "top",
      loading: false,
      articles: [],
      confirmDialog: false,
      customers: [],
      employees: [],
      customerInfo: {
        contact_id: "",
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        street: "",
        contact_no: "",
        contact_name: "",
        account_credit_balance: 0,
        account_credit_number: "",
        account_credit_title: "",
      },
      accounts: [],
      duedays: [],
      loadingButton: false,
      articleName: "",
      customerName: "",
      selectedArticle: "",
      dialogVisibleValid: false,
      customerDefault: null,
      terms: [],
      loadingArticle: false,
      loadingCustomer: false,
      items: [
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: 0,
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
      ],
      contactId: "",

      invoice: {
        contact_id: "",
        due_date: "",
        shipping_date: "",
        percent_tax: 0,
        percent_discount: true,
        discount: 0,
        date: formatDate(new Date()),
        terms: "",
        document_no: "",
        shipping_cost: 0,
        shipping_to: "",
        billing_to: "",
        reference: "",
        status: "OPEND",
        currency:"",
        sale_agent_number: "",
        line_items: [],
        note: "",
      },
      rules: {
        contact_id: [
          {
            required: true,
            message: "Veuillez selectionner un client",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "La date est obligatoire",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user", "preferences"]),
    totalHT: function () {
      var subtotal = 0;

      this.items.forEach((el) => {
        let totalHT = el.quantity * el.price;
        let discount = 0;
        let tax;
        if (el.percent_discount) {
          discount = (totalHT * el.discount) / 100;
        } else {
          discount = el.discount;
        }
        tax = ((totalHT - discount) * el.percent_tax) / 100;
        subtotal += totalHT - discount + tax;
      });

      return Math.floor(subtotal);
    },
    totalTTC: function () {
      var total = 0;
      if (this.invoice.percent_discount) {
        total =
          this.totalHT +
          this.invoice.shipping_cost -
          (this.totalHT * this.invoice.discount) / 100 +
          (this.totalHT * this.invoice.percent_tax) / 100;
      } else {
        total =
          this.totalHT +
          this.invoice.shipping_cost -
          this.invoice.discount +
          (this.totalHT * this.invoice.percent_tax) / 100;
      }
      return Math.floor(total);
    },
    itemsNoEmpty: function () {
      return this.items.some((item) => item.article_id !== "");
    },
  },
  mounted() {
    this.initData();
    this.fetchEmployee();
  },
  methods: {
    async initData() {
      await getDocumentSaleNo(this.typeDoc).then((res) => {
        this.invoice.document_no = res.data.number;
      });
      this.processingData();
    },
    async fetchEmployee() {
      await getEmployeesList("SALES_AGENT")
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "danger",
          });
        });
    },

    createData() {
      this.$refs["invoiceForm"].validate((valid) => {
        this.confirmDialog = false;
        if (valid) {
          this.loading = true;
          this.invoice.line_items = this.items.filter(
            (el) => el.article_id !== "" && el.article_id !== undefined
          );
          this.invoice.currency = this.user.currency_code;

          newDocument(this.invoice, this.typeDoc)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$router.push(`/sales/index?tab=CREDIT_NOTE`);
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loading = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 409 || err.data.status === 404) {
                this.$message({
                  message: err.data.message,
                  type: "danger",
                });
              } else {
                this.$message({
                  message:
                    "Erreur serveur ! Merci de contacter l'administrateur",
                  type: "danger",
                });
              }
            });
        }
      });
    },
    // methode item
    handleCleanItem() {
      this.invoice.line_items = [];
    },
    handleSearchArticle(data) {
      this.articles = data;
    },
    handeAddArticle() {
      this.items.push({
        article_id: "",
        article_no: "",
        article_name: "",
        price_purchase: 0,
        price_sale: 0,
        description: "",
        price: "",
        percent_tax: 0,
        percent_discount: this.preferences.currentVal === 'true',
        discount: 0,
        quantity: 0,
        warehouse_code: "",
        warehouse_name: "",
        stock_onhand: "",
        stocks: [],
      });
    },
    handleSelectedCustomer(id) {
      // selectionner le contact
      const customer = this.customers.find((c) => c.contact_id === id);
      this.customerInfo = Object.assign({}, customer);
      this.invoice.contact_id = customer.contact_id;
    },
    handleSearchCustomer(data) {
      this.customers = data;
    },

    handleDeleteItem(id) {
      const index = this.items.findIndex((el) => el.article_id === id);
      this.items.splice(index, 1);
    },

    updateDiscount(e) {
      if (this.invoice.percent_discount) {
        if (e > 100) {
          this.invoice.discount = 0;
        } else {
          this.invoice.discount = e;
        }
      } else {
        this.invoice.discount = e;
      }
    },
    updatePercentDiscount(e) {
      this.invoice.percent_discount = e;
      this.invoice.discount = 0;
    },
    updateTaxe(e) {
      this.invoice.percent_tax = e;
    },
    updateShipping(e) {
      this.invoice.shipping_cost = e;
    },

    handleCreate(e) {
      this.invoice.status = e;
      this.createData();
    },
    processingData(){
      this.invoice.percent_discount = this.preferences.currentVal === 'true';
      this.items.map(el => {
        el.percent_discount = this.preferences.currentVal === 'true';
        return el;
      })
    }
  },
};
</script>

<style></style>
